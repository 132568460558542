.root {
  background: #111111;
  height: 100vh;
  color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
   /* TODO: make image work */
  /* background-image: url('./login-bg.svg'); */
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
}

.label {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #fafafa;
  margin-bottom: 8px;
  display: block;
}

.input {
  margin-bottom: 32px;
  display: block;
}

.enter {
  display: flex;
  /* grid-template-columns: 104px 12px auto;
  grid-gap: 8px; */
}

.submit {
  margin-right: 16px;
}
.checkbox {
  margin-right: 8px;
}

.enter > * {
  align-self: center;
}

.textInput {
  position: relative;
}
.incorrect {
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% + 4px));
}

.buttonText {
  font-weight: 500;
}

.googleButton {
  margin-right: 10px;
}

.checkboxArea {
  cursor: pointer;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1460px) {
  .root {
    background-size: cover;
  }
}

@media screen and (max-width: 600px) {
  .root {
    /* TODO: make image work */
    /* background-image: url('./login-bg.svg'); */
    background-size: cover;
    background-position: 0 0;
  }
}
