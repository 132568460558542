.root {
  border-radius: 16px;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 232px;
}

.on .header {
  background: #32bc42;
  color: #dddddd;
}

.off .header {
  background: #111111;
  color: #444444;
}

.root .header {
  font-size: 24px;
  line-height: 32px;
  /* padding: 44px 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  height: 50%;
}

.footer {
  /* min-height: 116px;/ */
  background: #eeeeee;
  padding: 16px 24px;
  height: 50%;
  box-sizing: border-box;
}
