.root {
  width: 100%;
  height: 100vh;
  background-color: #444444;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;

  text-align: center;
  color: #111111;
  padding: 52px 90px;
  background: #fafafa;
  border-radius: 156px;
  border: 0;
  cursor: pointer;
  transition: 0.3s ease all;
}

.button:hover {
  color: #fafafa;
  background-color: #111111;
}
