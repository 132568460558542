.root {
  height: 220px;
}

.bar {
  background: #1356a8;
  border-radius: 4px;
  display: inline-block;
  height: 24px;
}

.row {
  display: flex;
  margin-bottom: 16px;
  word-wrap: break-word;
  align-items: center;
}

.docName {
  width: 140px;
  min-width: 78px;
  margin-right: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  height: 100%;
}

.time {
  margin-right: 8px;
  height: 24px;
  width: 60px;
  text-align: right;
}

.barContainer {
  width: 100%;
}
