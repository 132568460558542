.layout {
  position: relative;
}

.header {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
