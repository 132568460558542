.root {
  min-height: 300px;
  height: auto;
  overflow: scroll;
}

.bar {
  background: #1356a8;
  border-radius: 4px;
  display: inline-block;
  height: 24px;
}

.row {
  display: flex;
  margin-bottom: 16px;
  word-wrap: break-word;
  align-items: flex-start;
}

.docName {
  width: 140px;
  min-width: 140px;
  margin-right: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.quantity {
  height: 24px;
  width: 80px;
  text-align: right;
  margin-right: 8px;
}

.barContainer {
  width: 100%;
}

.fallback {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
