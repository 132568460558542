body {
  --accent-color: var(--blue);
  --light-gray: #eeeeee;
  --dark-gray: #aaaaaa;
  --gray: #dddddd;
  --disabled: #aaaaaa;
  --dark-blue: #002a5b;
  --blue: #1356a8;
  --green: #2ac178;
  --red: #c60c31;
  --dark-red: #b6000d;
  --black: #111111;
  --light-black: #444444;
  --white: #fafafa;
  color: var(--black);
  background-color: var(--white);
}

._Button-module__root__RpsiW {
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  color: var(--black);
  background: var(--white);
  border-radius: 12px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;
  outline: none;
  font-family: inherit;
  border: 2px solid transparent;
}

._Button-module__defaultForm__TSHkR {
  border-radius: 12px;
}

._Button-module__circleForm__KvTNt {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

._Button-module__sizeM__3uA6t {
  font-size: 16px;
  font-weight: 500;
}

._Button-module__defaultForm__TSHkR._Button-module__sizeM__3uA6t {
  border-radius: 24px;
  padding: 14px 24px;
}

._Button-module__circleForm__KvTNt._Button-module__sizeM__3uA6t {
  width: 60px;
  height: 60px;
}

._Button-module__sizeS__1Ovlj {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

._Button-module__defaultForm__TSHkR._Button-module__sizeS__1Ovlj {
  border-radius: 16px;
  padding: 9px 24px;
}

._Button-module__circleForm__KvTNt._Button-module__sizeS__1Ovlj {
  width: 50px;
  height: 50px;
}

._Button-module__sizeXS__3oLgK {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

._Button-module__circleForm__KvTNt._Button-module__sizeS__1Ovlj {
  width: 40px;
  height: 40px;
}

._Button-module__defaultForm__TSHkR._Button-module__sizeXS__3oLgK {
  border-radius: 14px;
  padding: 4px 10px;
}

._Button-module__white__2NMAm {
  background: var(--white);
  color: var(--black);
}

._Button-module__blue__1Ewuq {
  background: var(--blue);
  color: var(--white);
}

._Button-module__red__3Niou {
  background: var(--red);
  color: var(--white);
}

._Button-module__gray__3IM54 {
  background: var(--gray);
  color: var(--black);
}

._Button-module__blueBordered__3Zs8D {
  background: transparent;
  color: var(--blue);
  border-color: var(--blue);
  border-width: 2px;
  border-style: solid;
}

._Button-module__blueBordered__3Zs8D:hover {
  color: var(--dark-blue);
  border-color: var(--dark-blue);
}

._Button-module__blue__1Ewuq:hover {
  background: var(--dark-blue);
}

._Button-module__red__3Niou:hover {
  background: var(--dark-red);
}

._Button-module__white__2NMAm:hover {
  background: var(--gray);
}

._Button-module__black__3sG3Y:hover {
  background: var(--white);
  color: var(--black);
}

._Button-module__orange__2zEaz {
  background-color: #e08b09;
  color: var(--white);
}

._Button-module__orange__2zEaz:hover {
  background-color: #c07608;
  color: var(--white);
}

._Button-module__black__3sG3Y {
  background: var(--black);
  color: var(--white);
}

._Button-module__blackOnWhite__KqAO0:hover {
  background: var(--light-black);
  color: var(--white);
}

._Button-module__blackOnWhite__KqAO0 {
  background: var(--black);
  color: var(--white);
}

._Button-module__blue__1Ewuq {
  background: var(--blue);
}

._Button-module__green__3HYm4 {
  background: #32bc42;
  color: var(--white);
}

._Button-module__green__3HYm4:hover {
  background: #258d31;
  color: var(--white);
}

._Button-module__blue__1Ewuq:hover {
  background: var(--dark-blue);
}

._Button-module__disabled__-5sms {
  background: var(--disabled);
  border-color: var(--disabled);
  color: var(--white);
  cursor: default;
}

._Button-module__disabled__-5sms:hover {
  background: var(--disabled);
  border-color: var(--disabled);
  color: var(--white);
}

._Button-module__link__2NZ9O,
._Button-module__whiteLink__Jkm39 {
  border: none;
  background: transparent;
  padding: 0;
  font-weight: 500;
  color: var(--blue);
}

._Button-module__linkButton__28jO9 {
  border: none;
  background: transparent;
}

._Button-module__link__2NZ9O {
  color: var(--blue);
  border-bottom: 1px dotted var(--blue);
  border-radius: 0;
  line-height: 1;
}

._Button-module__defaultForm__TSHkR._Button-module__link__2NZ9O {
  border-radius: 0;
  padding: 0;
  line-height: 1.6;
}

._Button-module__link__2NZ9O:hover {
  color: var(--dark-blue);
  border-color: var(--dark-blue);
}

._Button-module__whiteLink__Jkm39 {
  color: var(--white);
}

._Button-module__isLoading__1g6QO {
  color: transparent !important;
}

._Button-module__isLoading__1g6QO._Button-module__black__3sG3Y:hover {
  background: var(--black) !important;
  color: var(--white);
}

._Loader-module__dot__3lc2r {
  width: 4px;
  height: 4px;
  display: block;
  background-color: #fafafa;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  /* opacity: 0; */
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: _Loader-module__opacify__24BX_;
          animation-name: _Loader-module__opacify__24BX_;
}

._Loader-module__dot__3lc2r:nth-child(1) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

._Loader-module__dot__3lc2r:nth-child(2) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

._Loader-module__dot__3lc2r:nth-child(3) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

._Loader-module__dot__3lc2r:nth-child(4) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

._Loader-module__loader__1rMsS {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

._Loader-module__loader__1rMsS._Loader-module__fullscreen__2aSkg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

@-webkit-keyframes _Loader-module__opacify__24BX_ {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes _Loader-module__opacify__24BX_ {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

._Textarea-module__root__3FrXj {
  color: var(--black);
}

._Textarea-module__input__3MgaV {
  border-radius: 6px;
  background: var(--light-gray) !important;
  border-radius: 6px;
  border: 0;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;
  width: 100%;
  outline: none;
  border: 2px solid transparent;
  box-sizing: border-box;
  font-feature-settings: 'liga' off;
}

._Textarea-module__input__3MgaV::-moz-placeholder {
  color: #aaa;
}

._Textarea-module__input__3MgaV:-ms-input-placeholder {
  color: #aaa;
}

._Textarea-module__input__3MgaV::placeholder {
  color: #aaa;
}

._Textarea-module__input__3MgaV:focus {
  border: 2px solid var(--blue);
  width: 100%;
}

._Textarea-module__whiteOnBlue__-Rq9o:focus {
  border: 2px solid var(--black);
}

._TextInput-module__root__1q3lK {
  font-style: normal;
  color: var(--black);
  position: relative;
}

._TextInput-module__input__3KS9n {
  background: var(--light-gray);
  outline: none;
  border: 2px solid transparent;
  box-sizing: border-box;
  font-family: inherit;
  font-weight: 500;
}

._TextInput-module__standard__2j9gE {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;
  border-radius: 6px;
  width: 100%;
}

._TextInput-module__transparent__ih57q ._TextInput-module__input__3KS9n {
  background: transparent;
  border: none;
}

._TextInput-module__transparent__ih57q ._TextInput-module__input__3KS9n:focus {
  border: none;
}

._TextInput-module__small__2Qidy {
  font-size: 14px;
  line-height: 18px;
  padding: 6px 8px;
  border-radius: 4px;
  width: 100%;
}

._TextInput-module__large__2KN1c {
  font-size: 56px;
  line-height: 64px;
  font-weight: 500;
}

._TextInput-module__input__3KS9n::-moz-placeholder {
  color: #aaa;
}

._TextInput-module__input__3KS9n:-ms-input-placeholder {
  color: #aaa;
}

._TextInput-module__input__3KS9n::placeholder {
  color: #aaa;
}

._TextInput-module__whiteOnBlue__1Y0RB ._TextInput-module__input__3KS9n:focus {
  border: 2px solid var(--black);
}

._TextInput-module__input__3KS9n:focus {
  border: 2px solid var(--blue);
}

._TextInput-module__error__1RQab ._TextInput-module__input__3KS9n {
  border-color: var(--red);
}

._TextInput-module__white__LZXs8 input {
  background: var(--white) !important;
}

._TextInput-module__errorText__2stBj {
  font-size: 14px;
  color: var(--red);
  font-weight: normal;
  margin-top: 8px;
  position: absolute;
  bottom: -1.57em;
}

._TextInput-module__oneSymboled__16O9o input {
  width: 100%;
  max-width: 40px;
  padding: 4px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
}

._TextInput-module__oneSymboled__16O9o {
  display: inline;
}

._TextInput-module__toggleVisibility__MV15o svg {
  height: 20px;
  width: 20px;
}

._TextInput-module__toggleVisibility__MV15o {
  position: absolute;
  right: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  justify-content: center;
  cursor: pointer;
  width: 20px;
  opacity: 0.3;
  transition: 0.3s ease all;
}

._TextInput-module__password__1FPb9 {
  padding-right: calc(12px + 20px + 4px);
}

._TextInput-module__toggleVisibility__MV15o:hover {
  opacity: 0.8;
}

._Checkbox-module__root__3mOXt {
  border: 2px solid;
  height: 12px;
  width: 12px;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

._Checkbox-module__relative__3pzSG {
  position: relative;
  top: 6px;
}

._Checkbox-module__box__rIrlt {
  width: 4px;
  height: 4px;
  display: block;
}

._Checkbox-module__gray__2JxJA {
  border-color: #aaa;
}

._Checkbox-module__gray__2JxJA ._Checkbox-module__box__rIrlt {
  background-color: #aaa;
}

._Checkbox-module__white__3bc3Y {
  border-color: #fafafa;
}
._Checkbox-module__white__3bc3Y ._Checkbox-module__box__rIrlt {
  background-color: #fafafa;
}

._Checkbox-module__checkmark__3n85U {
  width: 24px;
  height: 24px;
  background-color: var(--light-gray);
  border: none;
  border-radius: 4px;
  flex-shrink: 0;
}

._Checkbox-module__checkmarkContainer__2Cwqm {
  line-height: 24px;
}

._Checkbox-module__checkmark__3n85U._Checkbox-module__relative__3pzSG {
  position: static;
}

._Checkbox-module__black__vwwxk {
  border-color: var(--black);
}

._Checkbox-module__black__vwwxk ._Checkbox-module__box__rIrlt {
  background-color: var(--black);
}

._Checkbox-module__label__3kdrc {
  cursor: pointer;
}

._Checkbox-module__centered__2E4nV {
  align-items: center;
}

._Checkbox-module__container__1LyBL {
  display: flex;
  gap: 8px;
}

._Heading-module__root__mBjET {
  font-family: 'TT Norms';
  font-weight: 500;
  line-height: 1.2;
}

._Heading-module__center__3Ko9t {
  text-align: center;
}

._Heading-module__h1__1PSPT {
  font-size: 72px;
  margin-bottom: 36px;
}

._Heading-module__h2__1Xp9g {
  font-size: 48px;
  margin-bottom: 60px;
}

._Heading-module__h3__16UsP {
  font-size: 32px;
  margin-bottom: 20px;
}

._Heading-module__h4__19kgC {
  font-size: 24px;
  margin-bottom: 12px;
}

._Heading-module__h5__2vsPB {
  font-size: 20px;
  margin-bottom: 12px;
}

._Heading-module__h6__3Qnqt {
  font-size: 16px;
  margin-bottom: 8px;
}

@media screen and (max-width: 600px) {
  ._Heading-module__h1__1PSPT {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 24px;
  }

  ._Heading-module__h2__1Xp9g {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 24px;
  }

  ._Heading-module__h3__16UsP {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  ._Heading-module__noMbMobile__1QApU {
    margin-bottom: 0;
  }
}

._Text-module__root__R_6c8 {
  font-style: normal;
  font-weight: normal;
  font-feature-settings: 'liga' off;
}

._Text-module__standard__3KO36 {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}

._Text-module__big__1kbNE {
  font-size: 24px;
  line-height: 36px;
}

._Text-module__boldBlue__3Ehi9 {
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: var(--blue);
}

@media screen and (max-width: 600px) {
  ._Text-module__big__1kbNE {
    font-size: 20px;
    line-height: 1.6;
  }
}

._Switch-module__switch__21RHc {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  min-width: 32px;
}

/* Hide default HTML checkbox */
._Switch-module__switch__21RHc input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
._Switch-module__slider__U3CHN {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fafafa;
  border-radius: 16px;
  transition: 0.4s;
}

._Switch-module__slider__U3CHN:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 0;
  bottom: 0;
  background-color: #aaaaaa;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + ._Switch-module__slider__U3CHN:before {
  background-color: #1356a8;
}

input:checked + ._Switch-module__slider__U3CHN:before {
  transform: translateX(100%);
}

/* Rounded sliders */
._Switch-module__slider__U3CHN._Switch-module__round__3kZJ1 {
  border-radius: 34px;
}

._Switch-module__slider__U3CHN._Switch-module__round__3kZJ1:before {
  border-radius: 50%;
}

@import '~react-datepicker/dist/react-datepicker.css';

._ThreeDots-module__root__1hiiH {
  display: grid;
  grid-template-columns: 8px 8px 8px;
  gap: 4px;
  cursor: pointer;
  position: relative;
  padding: 10px;
  /* display: inline-block; */
}

._ThreeDots-module__root__1hiiH:hover ._ThreeDots-module__dot__2zfl8 {
  background: #444444;
}

._ThreeDots-module__dot__2zfl8 {
  width: 8px;
  height: 8px;
  background: #aaaaaa;
  border-radius: 50%;
}

._ThreeDots-module__popup__1mWV2 {
  background: #111111;
  padding: 16px 24px;
  position: absolute;
  /* top: -50px; */
  left: 0;
  transform: translateX(-50%);
  border-radius: 12px;
  z-index: 30;
}

._Tooltip-module__tooltipContent__3BZlO {
  background: var(--black) !important;
  border-radius: 4px;
  color: var(--white) !important;
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 10px 16px !important;
}

._Tooltip-module__arrow__jvjis {
  color: var(--black) !important;
  fill: var(--black) !important;
}

._QuestionIcon-module__root__2mmoP {
  background: #aaaaaa;
  border-radius: 8px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

._QuestionIcon-module__root__2mmoP:hover {
  background-color: var(--black);
}

._BinaryToggler-module__root__16aZE {
  background: #dddddd;
  border-radius: 12px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  position: relative;
}

._BinaryToggler-module__item__19J4k {
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #111111;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

._BinaryToggler-module__box__1iMXB {
  transform: translateX(100%);
  background: #aaaaaa;
  border-radius: 12px;
  cursor: default;
  display: block;
  height: 100%;
  width: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  transition: 0.3s ease all;
}

._BinaryToggler-module__activeItem__3vfl5 {
  color: #fafafa;
}

._BinaryToggler-module__active__2ZJTu ._BinaryToggler-module__box__1iMXB {
  transform: translateX(0);
}

._BinaryToggler-module__activeItem__3vfl5 svg path {
  fill: #fafafa;
}

._Hamburger-module__hamburger__1n4aw {
  position: relative;
  width: 48px;
  height: 48px;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  display: block;
  margin-left: 12px;
  margin-right: -12px;
}

._Hamburger-module__hamburger-line__1FHXv {
  display: block;
  background: var(--black);
  width: 24px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: transform;
}

._Hamburger-module__light__1JUVO ._Hamburger-module__hamburger-line__1FHXv {
  background: var(--white);
}

._Hamburger-module__hamburger-line__1FHXv:first-child {
  transform: translate(-50%, 4px);
}

._Hamburger-module__hamburger-line__1FHXv:last-child {
  transform: translate(-50%, -4px);
}

._Hamburger-module__hamburger__1n4aw:not(:hover),
._Hamburger-module__hamburger__1n4aw:not(:hover) ._Hamburger-module__hamburger-line__1FHXv {
  -webkit-animation-duration: 0s !important;
          animation-duration: 0s !important;
}

._Hamburger-module__enabled__nInxt ._Hamburger-module__hamburger-line__1FHXv:first-child {
  -webkit-animation: _Hamburger-module__topLineOn__23-ll 0.1s linear forwards;
          animation: _Hamburger-module__topLineOn__23-ll 0.1s linear forwards;
}
._Hamburger-module__enabled__nInxt ._Hamburger-module__hamburger-line__1FHXv:last-child {
  -webkit-animation: _Hamburger-module__bottomLineOn__Uztkn 0.1s linear forwards;
          animation: _Hamburger-module__bottomLineOn__Uztkn 0.1s linear forwards;
}

/* .disabled .hamburger-line:first-child {
  animation: topLineOff 0.1s linear forwards;
}

.disabled .hamburger-line:last-child {
  animation: topLineOff 0.1s linear forwards;
} */

@-webkit-keyframes _Hamburger-module__topLineOn__23-ll {
  0% {
    transform: translate(-50%, -4px);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, 0) rotate(45deg);
  }
}

@keyframes _Hamburger-module__topLineOn__23-ll {
  0% {
    transform: translate(-50%, -4px);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, 0) rotate(45deg);
  }
}

@-webkit-keyframes _Hamburger-module__bottomLineOn__Uztkn {
  0% {
    transform: translate(-50%, 4px);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, 0) rotate(-45deg);
  }
}

@keyframes _Hamburger-module__bottomLineOn__Uztkn {
  0% {
    transform: translate(-50%, 4px);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, 0) rotate(-45deg);
  }
}

@-webkit-keyframes _Hamburger-module__topLineOff__1Q3Wp {
  0% {
    transform: translate(-50%, 0) rotate(45deg);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, -4px);
  }
}

@keyframes _Hamburger-module__topLineOff__1Q3Wp {
  0% {
    transform: translate(-50%, 0) rotate(45deg);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, -4px);
  }
}

@-webkit-keyframes _Hamburger-module__bottomLineOff__2wvci {
  0% {
    transform: translate(-50%, 0) rotate(-45deg);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, 4px);
  }
}

@keyframes _Hamburger-module__bottomLineOff__2wvci {
  0% {
    transform: translate(-50%, 0) rotate(-45deg);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, 4px);
  }
}

._Pagination-module__root__16rCd {
  display: flex;
  gap: 12px;
  position: relative;
}

._Pagination-module__item__3vA_K,
._Pagination-module__dots__TmPFx {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  border-radius: 24px;
  color: var(--black);
  cursor: pointer;
}

._Pagination-module__item__3vA_K:hover {
  background-color: var(--gray);
}

._Pagination-module__item__3vA_K._Pagination-module__active__1Bu0R {
  color: var(--white);
  background-color: var(--black);
  cursor: default;
}

._Pagination-module__arrow__35Lnc._Pagination-module__right__QbFJG {
  transform: translateX(calc(100% + 12px)) rotate(-180deg);
  right: 0;
  left: auto;
}

._Pagination-module__arrow__35Lnc {
  position: absolute;
  left: 0;
  transform: translateX(calc(-100% - 12px));
  border: 2px solid #111111;
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  cursor: pointer;
  height: 100%;
}

._Pagination-module__arrow__35Lnc:hover {
  background-color: var(--gray);
}
