.root {
  height: 100vh;
  box-sizing: border-box;
  background: #444444;
  padding: 96px 0 48px;
}

.statusFooter {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #111111;
  text-align: left;
}

.grids {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}

.leftGrid {
  display: grid;
  grid-template-rows: 232px auto;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  grid-template-areas:
    'a b'
    'c c';
}

.frequency {
  grid-area: c;
}
.statusA {
  grid-area: a;
}
.statusB {
  grid-area: b;
}

.rightGrid {
  display: grid;
  grid-template-rows: auto auto;
  gap: 32px;
}

@media screen and (max-width: 600px) {
  .root {
    height: auto;
  }
  .grids {
    grid-template-rows: auto auto;
    grid-template-columns: none;
  }

  .leftGrid {
    grid-template-areas:
      'a a'
      'b b'
      'c c';
  }
  .widget {
    margin-bottom: 32px;
  }
}
