.root {
  height: 48px;
  display: flex;
  align-items: center;
  background-color: #111111;
  width: 100%;
  box-sizing: border-box;
}

.logo {
  cursor: pointer;
}

.transparent {
  background-color: transparent;
  padding: 0 24px;
}
