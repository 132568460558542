.root {
  background: #eeeeee;
  border-radius: 16px;
  padding: 24px;
  box-sizing: border-box;
}

.circle {
  width: 20px;
  height: 20px;
  background: #32bc42;
  display: inline-block;
  border-radius: 50%;
}

.heading {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
  display: grid;
  grid-template-columns: 20px auto;
  gap: 8px;
  margin-bottom: 8px;
  font-weight: bold;
}

.capacity {
  font-size: 32px;
  line-height: 40px;
  color: #aaaaaa;
  margin-bottom: 16px;
}

.recognized {
  color: #000000;
}

.period {
  font-size: 14px;
  line-height: 16px;
  color: #aaaaaa;
}

.tooltip {
  background: #111111;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fafafa;
  padding: 6px 10px;
  position: relative;
}

.tickText {
  font-size: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  /* letter-spacing: -0.24em; */
  fill: #000000;
  font-family: 'TT Norms';
}

@media screen and (max-width: 600px) {
  .capacity {
    font-size: 24px;
    line-height: 24px;
  }
}
